import { clsx } from 'clsx';
import Head from 'next/head';

import { usePageAuthorization } from './page-authorization.hook';
import style from './style.module.scss';

interface IPageProps extends ICustomizable {
  title?: string;
  description?: string;
  icon?: string;
  private?: boolean;
  admin?: boolean;
  complianceReviewer?: boolean;
  children?: React.ReactNode;
  tradeDataViewer?: boolean;
}

const SEO = {
  title: 'Ping',
  description: 'Cryptocurrency Exchange Dashboard',
  icon: '/favicon.svg',
};

export const Page = (props: IPageProps) => {
  const {
    children,
    title,
    description,
    icon,
    private: isPrivate,
    admin: isAdmin,
    complianceReviewer: isComplianceReviewer,
    tradeDataViewer: isTradeDataViewer,
  } = props;
  const isAuthorized = usePageAuthorization({ isPrivate, isAdmin, isComplianceReviewer, isTradeDataViewer });

  return (
    <>
      {isAuthorized && (
        <>
          <Head>
            <title>{title ?? SEO.title}</title>
            <meta name='description' content={description ?? SEO.description} />
            <link rel='icon' href={icon ?? SEO.icon} />
          </Head>

          <main className={clsx(style['page'], props.className)}>{children}</main>
        </>
      )}
    </>
  );
};
