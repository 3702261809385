import React from 'react';
import clsx from 'clsx';
import { Text } from '@ping/uikit/Text';
import style from './style.module.scss';

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  text: string;
}

export const Checkbox = ({ className, text, ...rest }: IProps) => {
  return (
    <div className={clsx(style['checkbox-wrapper'], className)}>
      <label className={style['checkbox-label']}>
        <input type='checkbox' {...rest} />
        <span className={style['checkbox-mark']} />
        <Text body='regular' className={style['checkbox-text']}>
          {text}
        </Text>
      </label>
    </div>
  );
};
