import { useTable as useReactTable, useRowSelect, useBlockLayout, useSortBy } from 'react-table';
import { useSticky } from 'react-table-sticky';
import { useMobile } from '@ping/hooks';
import { useRowSelectCheckbox } from '@ping/uikit/Table/useRowSelectCheckbox';

interface IUseTable {
  data: any[];
  columns: any[];
  isLoading?: boolean;
  initialState?: Record<string, any>;
  disableAllColumnsSorting?: boolean;
  isStickyColumn?: boolean;
  isMobileStickyColumn?: boolean;
  hasRowSelect?: boolean;
  manualSortBy?: boolean;
  disableSortRemove?: boolean;
}

const useTable = ({
  data = [],
  columns,
  isLoading,
  initialState,
  disableAllColumnsSorting = false,
  isStickyColumn = false,
  isMobileStickyColumn = true,
  hasRowSelect = false,
  ...rest
}: IUseTable) => {
  const isMobile = useMobile();
  const enableStickyForMobile = isMobileStickyColumn && isMobile;
  const hasData = data?.length !== 0;

  // Use the useTable Hook to send the columns and data to build the table
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
    selectedFlatRows,
    toggleAllRowsSelected,
  } = useReactTable(
    {
      data,
      columns,
      // For default sorting (based on date and time)
      initialState: initialState || { sortBy: [{ id: 'createdAt', desc: true }] },
      ...rest,
    },
    !disableAllColumnsSorting && useSortBy,
    hasData && !isLoading && (isStickyColumn || enableStickyForMobile) && useBlockLayout,
    hasData && !isLoading && (isStickyColumn || enableStickyForMobile) && useSticky,
    hasRowSelect && useRowSelect,
    hasRowSelect && useRowSelectCheckbox
  );

  return {
    data,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    isMobile,
    enableStickyForMobile,
    selectedFlatRows,
    toggleAllRowsSelected,
  };
};

export default useTable;
