import { Checkbox } from '@ping/uikit';

export const useRowSelectCheckbox = hooks => {
  hooks?.visibleColumns?.push(columns => [
    {
      id: 'selection',
      Header: table => {
        return <Checkbox {...table.getToggleAllRowsSelectedProps()} />;
      },
      Cell: ({ row }) => {
        return <Checkbox {...row.getToggleRowSelectedProps()} />;
      },
    },
    ...columns,
  ]);
};
