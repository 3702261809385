import { useMemo } from 'react';

import style from './style.module.scss';

interface IUseTableColumns {
  columns: any[];
  isLoading: boolean;
}

const useTableColumns = ({ isLoading, columns }: IUseTableColumns) => {
  return useMemo(() => {
    if (isLoading) {
      // Create Skeleton for loading
      return columns.map(column => ({
        ...column,
        Cell: <div className={style['skeleton']}>&nbsp;</div>,
      }));
    }
    return columns;
  }, [isLoading, columns]);
};

export default useTableColumns;
