import { useMemo } from 'react';

interface IUseTableData {
  data: any[];
  isLoading: boolean;
  loadingItemsPerPage?: number;
  hasInfiniteLoading?: boolean;
}

const useTableData = ({ data, isLoading, loadingItemsPerPage = 10, hasInfiniteLoading = false }: IUseTableData) => {
  return useMemo(() => {
    if (isLoading) {
      // Create Skeleton for loading
      return Array(loadingItemsPerPage).fill({});
    } else {
      if (hasInfiniteLoading) {
        const allPagesData = [];
        if (data.length !== 0) {
          for (const item of data) {
            allPagesData.push(...item.data);
          }
        }
        return allPagesData;
      }
      return data;
    }
  }, [isLoading, data]);
};

export default useTableData;
